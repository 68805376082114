import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "/var/www/bri/dashboard-bri-kk/source/node_modules/core-js/modules/es6.array.iterator.js";
import "/var/www/bri/dashboard-bri-kk/source/node_modules/core-js/modules/es6.promise.js";
import "/var/www/bri/dashboard-bri-kk/source/node_modules/core-js/modules/es6.object.assign.js";
import "/var/www/bri/dashboard-bri-kk/source/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css'; // a modern alternative to CSS resets

import Element from 'element-ui';
import "./styles/element-variables.scss";
import '@/styles/index.scss'; // global css

import App from "./App";
import store from "./store";
import router from "./router";
import "./icons"; // icon
import "./permission"; // permission control
import "./utils/error-log"; // error log

import * as filters from "./filters"; // global filters

import { library } from '@fortawesome/fontawesome-svg-core';
import { faFilter, faSpinner, faTasks, faListAlt, faExclamationCircle, faUserCheck, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueHtml2Canvas from 'vue-html2canvas';
import Skelenton from 'vue-loading-skeleton';
import "../src/styles/viewer.css";
import Viewer from 'v-viewer';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
Sentry.init({
  dsn: 'https://d60e9b611232422f91367049378b728f@o371019.ingest.sentry.io/5207907',
  environment: process.env.NODE_ENV,
  integrations: [new VueIntegration({
    Vue: Vue,
    attachProps: true
  })]
});
Vue.use(Viewer);
library.add(faFilter, faSpinner, faTasks, faListAlt, faExclamationCircle, faUserCheck, faFileExcel);
Vue.component('font-awesome-icon', FontAwesomeIcon);

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
if (process.env.NODE_ENV === 'production') {
  var _require = require("../mock"),
    mockXHR = _require.mockXHR;
  mockXHR();
}
var moment = require('moment');
require('moment/locale/id');
Vue.use(require('vue-moment'), {
  moment: moment
});
Vue.use(Skelenton);
Vue.use(VueHtml2Canvas);
Vue.use(Element, {
  size: Cookies.get('size') || 'medium' // set element-ui default size
});

// register global utility filters
Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.config.productionTip = false;
Vue.config.devtools = true;
new Vue({
  el: '#app',
  router: router,
  store: store,
  Sentry: Sentry,
  render: function render(h) {
    return h(App);
  }
});