import "core-js/modules/es6.function.name";
import _objectSpread from "/var/www/bri/dashboard-bri-kk/source/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import { asyncRoutes, constantRoutes } from '@/router';

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(function (role) {
      return route.meta.roles.includes(role);
    });
  } else {
    return true;
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = _objectSpread({}, route);
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles);
      }
      res.push(tmp);
    }
  });
  return res;
}
var state = {
  routes: [],
  addRoutes: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = constantRoutes.concat(routes);
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref, payload) {
    var commit = _ref.commit;
    return new Promise(function (resolve) {
      var accessedRoutes;
      if (payload.roles.includes('admin')) {
        accessedRoutes = asyncRoutes || [];
      } else {
        accessedRoutes = filterAsyncRoutes(asyncRoutes, payload.roles);
      }
      var tempAccessedRoutes = accessedRoutes;
      accessedRoutes.forEach(function (routeItem, i) {
        if (routeItem.children) {
          routeItem.children.forEach(function (routeItemChildren, x) {
            if (routeItemChildren.name === 'CustomerList' && payload.brixKey) {
              delete tempAccessedRoutes[i].children[x];
            }
            if (routeItemChildren.name === 'CustomerListBTN') {
              tempAccessedRoutes[i].children[x].name = "Customer ".concat(payload.name, " BTN");
              if (!payload.roles.includes('Super') && (!payload.brixKey || payload.email !== 'btn@bri.co.id')) {
                delete tempAccessedRoutes[i].children[x];
              }
            }
            if (routeItemChildren.name === 'CustomerListLOS') {
              tempAccessedRoutes[i].children[x].name = "Customer ".concat(payload.name, " LOS");
              if (!payload.roles.includes('Super') && (!payload.brixKey || payload.email !== 'los@bri.co.id')) {
                delete tempAccessedRoutes[i].children[x];
              }
            }
          });
        }
      });
      commit('SET_ROUTES', tempAccessedRoutes);
      resolve(tempAccessedRoutes);
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};